import { Alert } from 'antd'
import { Link } from 'react-router-dom'
import React, { CSSProperties } from 'react'
import { IUser } from '../../../../types/profile.type'

interface IProps {
  user: IUser
  adminUserId: string | null
  className?: string
  sx?: CSSProperties
  alignEmail?: CSSProperties['textAlign']
}

const AdditionalNotificationArea = ({ user, adminUserId, className, sx, alignEmail = 'right' }: IProps) => {
  return (
    <div style={{ ...sx }} className={className}>
      {!!adminUserId && <p id="admin-account-email" style={{ textAlign: alignEmail, marginBottom: user?.status !== 'unverified' ? 0 : undefined }}>{user.email}</p>}
      {user?.status === 'unverified' && (
        <Alert
          message={
            <>
              Please verify your email address {user.email} to use IONI. You can resend verification email on
              your{' '}
              <Link to="/profile">profile page</Link>.
            </>
          }
          type="warning"
          style={{ marginTop: (!adminUserId ? 20 : 0) }}
        />
      )}
    </div>
  )
}

export default AdditionalNotificationArea
